import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Container from '../components/Layout/container';
import { MainSection100 } from '../components/Layout/sections';
import NewLayout from '../components/new_layout';
import ReadyToTalk from '../components/v2023/ReadyToTalk/ready-to-talk';
import Tag from '../components/v2023/UI/Tag/tag';
import Avatar from '../components/v2024/avatar/avatar';
import { LanguagePills } from '../components/v2024/language';
import { YouTubePlayer } from '../components/v2024/video';
import Toolbar from '../components/v2023/UI/Card/toolbar';
import SEO from '../components/seo';

const Videos = ({ data, location, pageContext }) => {
	const video = data.video;
	const lang = pageContext.langKey;
	const qtyLangs = Object.keys(pageContext.translates).length

	return (
		<NewLayout noHeader thinFooter pageContext={pageContext}>
		<SEO
			description={video.seo.meta_description}
			lang={lang}
			title={video.seo.title}
			image={video.seo.image_2}
			translates={video?.translates}
		/>
			<main className='video-page'>
				<MainSection100 sectionAlignLeft>
					<Container>
						<GatsbyImage image={getImage(video.logo_tecalis.localFile)} />
						{video.hero_tag.map((tag) => <Tag tag={tag} />)}
						<h1>{video.hero_title}</h1>
						<Container text>
							<ReactMarkdown children={video.hero_description} rehypePlugins={[rehypeRaw]} />
						</Container>
						<div className='post-detail'>
							{qtyLangs > 1 &&
								<LanguagePills lang={lang} translates={pageContext.translates} />
							}
							<Toolbar lang={lang} url={location.href} title={video.hero_title} />
						</div>
						<YouTubePlayer videoId={video.video.video_url}  chapters={video.video_chapters} lang={lang} />
						{/* <YoutubeVideoInline video={video.video} ref={playerRef} /> */}
						{/* <YoutubeChapters chapters={video.video_chapters} lang={lang} playerRef={playerRef} /> */}
					</Container>
				</MainSection100>
				<MainSection100>
					<Container>
						<h1>{video.speakers_title}</h1>
						<div className='grid-sm'>
							{video.speakers_speakers.map((speaker) => <Avatar avatar={speaker} vertical />)}
						</div>
					</Container>
				</MainSection100>
				<ReadyToTalk lang={lang} />
			</main>
		</NewLayout>
	);
};

export default Videos;

export const videosQuery = graphql`
	query ($id: String!, $langKey: String!) {
		video: strapiVideos(locale: { eq: $langKey }, id: { eq: $id }) {
			seo {
				title
				meta_description
				image_2 {
					localFile {
						publicURL
					}
				}
			}
			logo_tecalis {
				name
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(width: 147, formats: [WEBP], quality: 100)
					}
				}
			}
			hero_tag {
				name
				type
			}
			hero_title
			hero_description
			video {
				video_url
				video_preview {
					name
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(width: 972, formats: [WEBP], quality: 100, breakpoints: [512, 704, 928])
						}
					}
				}
			}
			video_chapters {
				title
				minute
				image {
					name
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(width: 428, formats: [WEBP], quality: 100, breakpoints: [237, 298])
						}
					}
				}
			}
			speakers_title
			speakers_speakers {
				name
				department
				whatsapp_prefix
				whatsapp
				linkedin
				mail
				phone_prefix
				phone
				avatar {
					name
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(width: 64, formats: [WEBP], quality: 100)
						}
					}
				}
			}
		}
	}
`;
