import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { getYouTubeEmbedUrl } from '../../utils/gatsby-helpers';
import ImageGroup from "../v2024/image/image-group";

export const YoutubeVideo100 = ({ video }) => {
	const [videoModal, setVideoModal] = useState(false);
    const [videoLoading, setVideoLoading] = useState(true)

	const openVideoModal = () => {
		setVideoModal(!videoModal);
	};
    
    const spinnerVideo = () => {
        setVideoLoading(!videoLoading)
    }

	return (
		<>
			<div className="container__media">
				<div className="container__media__image">
					<GatsbyImage
						alt={video.video_preview.alternativeText}
						image={getImage(video.video_preview.localFile)}
					/>
				</div>
				<a onClick={openVideoModal} className="button-icon" target="_blank" rel="noreferrer">
					<i className="icon-play"></i>
				</a>
			</div>

			{videoModal && (
				<div className="tc-modal">
					<div className="tc-modal__content">
						<div className="tc-modal__content__header">
							<button className="button-icon button-icon--sm" onClick={openVideoModal}>
								<i className="icon-close"></i>
							</button>
						</div>
						<div className="tc-modal__content__body">
							<iframe
								onLoad={spinnerVideo}
								loading="lazy"
								src={getYouTubeEmbedUrl(video.video_url)}
								title="YouTube video player"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen={true}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export const YoutubeVideoInline = forwardRef(({ video }, ref) => {
    const [videoLoading, setVideoLoading] = useState(true)
	const iframeRef = useRef()

	let url = getYouTubeEmbedUrl(video.video_url)
	useImperativeHandle(ref, () => ({
		seekTo(seconds) {
			alert(seconds)
			const iframe = iframeRef.current
			if (iframe) {
				const videoSrc = `${url}&start=${seconds}`
				iframe.src = videoSrc
			}
		}
	}))

    const spinnerVideo = () => {
        setVideoLoading(!videoLoading)
    }

	return (
		<>
			<iframe
				ref={iframeRef}
				onLoad={spinnerVideo}
				loading="lazy"
				src={url}
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen={true}
			/>
		</>
	);
});

export const YoutubeChapters = ({chapters, playing, seekTo, getCurrentTime}) => {
	const [intervalId, setIntervalId] = useState(null)
	const [chapter, setChapter] = useState(0)
	const maxChapterList = chapters.length - 2
	
	// Left button
	const showLeft = () => {
		if (0 < chapter){
			setChapter(chapter-1)
		}
	}
	// Right button
	const showRight = () => {
		if (document.getElementById('video_chapters').childNodes.length > chapter){
			setChapter(chapter+1)
		}
	}
	// Setting the chapter as active
	const setItemActive = (item, active, all = false) => {
		if (all) { // If all selected, remove active from every chapter
			const videoChapters = document.getElementById('video_chapters').childNodes
			videoChapters.forEach(item => {
				item.classList.remove('active')
			})
		}
		if (active) {
			if (!item.classList.contains('active')) {
				// If item was not active, set the chapter to it, so the user sees the change
				setChapterByItem(item)
			}
			item.classList.add('active')
		} else {
			item.classList.remove('active')
		}
	}
	// Element given, setting the chapter by this item to do the translate and show the active chapter
	// Activate when:
	//  -- Activating another chapter
	//  -- The video goes to antoher chapter and we didn't change it
	const setChapterByItem = (item) => {
		const chapters = Array.from(document.getElementById('video_chapters').children)
		const index = chapters.indexOf(item)
		if (index != chapter && index <= maxChapterList) {
			setChapter(index)
		} else if (index != chapter && index > maxChapterList) {
			setChapter(maxChapterList)
		}
	}

	const goToYoutubeTime = (time) => {
		const seconds = timeToSeconds(time)
		seekTo(seconds)
	}
	
	// Every time playing changes
	useEffect(() => {
		if (playing) {
			const id = setInterval(() => {
				// Every second looking for the different chapter
				const currentTime = getCurrentTime()
				const videoChapters = Array.from(document.getElementById('video_chapters').childNodes).reverse() // Starting from the back
				let activeOne = null
				videoChapters.forEach(item => {
					// If the seconds of the chapter are <= video seconds and there is no active one, we activate that item, else deactivating it
					if (item.dataset.seconds <= currentTime && !activeOne) {
						setItemActive(item, true)
						activeOne = item
					} else {
						setItemActive(item, false)
					}
				})
			}, 1000)
			setIntervalId(id)
		} else {
			// If the video is not playing, removing the interval
			clearInterval(intervalId)
		}
		return () => {clearInterval(intervalId)}
	}, [playing])

	// Everytime chapter changes
	useEffect(() => {
		if (chapter >= chapters.length) {
			setChapter(chapters.length - 1)
		} else if (chapter < 0) {
			setChapter(0)
		}
		const videoChapters = document.getElementById('video_chapters')
		if (chapter > 0) {
			// Show start button
			document.getElementsByClassName('carrousel-slider__beginning')[0].classList.add('show')
		} else {
			// Hide start button
			document.getElementsByClassName('carrousel-slider__beginning')[0].classList.remove('show')
		}
		if (chapter < maxChapterList) {
			// Show end button
			document.getElementsByClassName('carrousel-slider__end')[0].classList.add('show')
		} else {
			// Hide end button
			document.getElementsByClassName('carrousel-slider__end')[0].classList.remove('show')
		}
		const translate = calculateTranslate()
		videoChapters.style.transform = `translateX(-${translate}px)`;

		
	}, [chapter])
	const calculateTranslate = () => {
		const itemWidth = getRealWidth()
		const videoChaptersWidth = parseInt(getComputedStyle(document.getElementById('video_chapters')).width)
		const carrouselSliderWidth = parseInt(getComputedStyle(document.getElementById('carrousel-slider')).width)
		if (window?.screen.width > 576) {
			if (chapter == 1) {
				return (itemWidth/2)
			} else if (chapter >= maxChapterList) {
				return videoChaptersWidth - carrouselSliderWidth
			} else if (chapter > 1) {
				const translate = (itemWidth * chapter) - (itemWidth/2)
				const maxTranslate = (itemWidth * (maxChapterList)) - (itemWidth/2)
				if (translate > maxTranslate) {
					return maxTranslate
				} else {
					return translate
				}
			} else {
				return 0
			}
		} else {
			if (chapter > 0) {
				if (carrouselSliderWidth > itemWidth) {
					const aux = (carrouselSliderWidth - itemWidth) / 2
					const offset = aux - (itemWidth * chapter)
					if (offset < 0) {
						return offset * -1
					}
					return offset
				}
				return chapter * itemWidth
			}
			return 0
		}
	}

	// Swipe events
	const [touchStart, setTouchStart] = useState(0)
	// On touch start we store the point where it starts
	const handleTouchStart = (event) => {
		setTouchStart(event.touches[0].screenX)
	}
	// While moving
	const handleTouchMove = (event) => {
		// If there is no start, there is no move
		if (!touchStart) {
			return
		}
		
		// Getting the end
		let touchEnd = event.touches[0].screenX

		// Detecting direction
		if (touchEnd > touchStart) {
			// right
			setChapter(chapter - 1)
		} else {
			// left
			setChapter(chapter + 1)
		}

		// Start to 0 don't affect next swipes
		setTouchStart(0)
	}

    return (
		<>
			<div id="carrousel-slider" className='carrousel-slider'>
				<div className="carrousel-slider__beginning">
					<button className="button-icon button-icon--secondary button-icon--lg" onClick={() => showLeft()}>
						<i className="icon-arrow-left"></i>
					</button>
				</div>
				<div id="video_chapters" className="carrousel-slider__content" onTouchStart={handleTouchStart} 	onTouchMove={handleTouchMove} style={{transition: "all .5s ease-in-out"}}>
					{chapters.map((chapter, index) => {
						return <ImageGroup key={index} id={`video_chapter_${index}`} selectable basicInfoWithImage={chapter} data-seconds={timeToSeconds(chapter.minute)} onClick={() => {goToYoutubeTime(chapter.minute); setItemActive(document.getElementById(`video_chapter_${index}`), true, true)}} />
					})}
				</div>
				<div className="carrousel-slider__end show">
					<button className="button-icon button-icon--secondary button-icon--lg" onClick={() => showRight()}>
						<i className="icon-arrow-right"></i>
					</button>
				</div>
			</div>
			<div className="show-sm">
				<div className="button__wrapper">
					<button className="button-icon button-icon--secondary button-icon--lg" onClick={() => showLeft()}>
						<i className="icon-arrow-left"></i>
					</button>
					<button className="button-icon button-icon--secondary button-icon--lg" onClick={() => showRight()}>
						<i className="icon-arrow-right"></i>
					</button>
				</div>
			</div>
		</>
	)
}


export const YouTubePlayer = ({ videoId, chapters, lang }) => {
	videoId = getVideoId(videoId)
	const playerRef = useRef(null) // It has to be a reference in order to avoid that the rerender creates a new player
	const [playerPlaying, setPlayerPlaying] = useState(null)

	// Carga la API de YouTube solo si no está lista
	useEffect(() => {
		if (!window.YT) {
			const tag = document.createElement("script");
			tag.src = "https://www.youtube.com/iframe_api";
			tag.async = true;
			document.body.appendChild(tag);
		}
	}, []);

	const seekTo = (seconds) => {
		if (playerRef.current) {
			playerRef.current.seekTo(seconds, true)
		}
	}

	const getCurrentTime = () => {
		if (playerRef.current) {
			return playerRef.current.getCurrentTime()
		}
		return 0
	}

	const loadYoutubeIFrame = () => {
		if (!videoId) return;

		const onYouTubeIframeAPIReady = () => {
			if (playerRef.current) {
				playerRef.current.destroy(); // 🔥 Destruye el reproductor anterior
			}

			playerRef.current = new window.YT.Player('youtube-player', {
				videoId: videoId,
				events: {
					onStateChange: (event) => {
						setPlayerPlaying(event.data === window.YT.PlayerState.PLAYING)
					},
				},
				playerVars: {
					rel: 0,         // Evita que se muestren videos relacionados al final
					showinfo: 0,    // Oculta la información del video
					modestbranding: 1,
					hl: lang
				}
			});
			
			const playButton = document.querySelector('.media-video > .button-icon')
			playButton.onclick = () => playerRef.current.playVideo()
		}

		 // Si la API ya está lista, crea el reproductor de inmediato
		if (window.YT && window.YT.Player) {
			onYouTubeIframeAPIReady();
		} else {
			// Si la API aún no está cargada, espera a que se inicialice
			window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
		}
	}

	useEffect(() => {
		loadYoutubeIFrame()
	}, [lang, videoId])

	return <>
		<div className={`media-video media-video--video-inline ${playerPlaying ? 'play' : ''}`} >
			<div id="youtube-player">{/* Aquí es donde el reproductor se inyectará por la API de YouTube */}</div>
			<div className="button-icon">
				<i className="icon-play"></i>
			</div>
		</div>
		{(chapters) && 
			<YoutubeChapters chapters={chapters} seekTo={seekTo} getCurrentTime={getCurrentTime} playing={playerPlaying} />
		}
	</>
};

const timeToSeconds = (time) => {
	const [minutes, seconds] = time.split(':').map(Number)
	return seconds + (minutes*60)
}

const getRealWidth = () => {
	const videoChapters = document.getElementById('video_chapters')
	const videoChaptsrsStyle = getComputedStyle(videoChapters);
	const gap = parseInt(videoChaptsrsStyle.gap)
	const item = videoChapters.childNodes[0]
	const itemWidth = parseInt(getComputedStyle(item).width)
	const aux = itemWidth + gap
	return aux
}

/**
 * Method to get the video id from the url
 * 
 * @param {string} video 
 * @returns 
 */
const getVideoId = (video) => {
	try {
		const videoUrl = new URL(video)
		const videoUrlParams = new URLSearchParams(videoUrl.search)
		return videoUrlParams.get('v')
	} catch (e) {
		return video
	}
}