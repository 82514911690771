import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const ImageGroup = ({ basicInfoWithImage, small = false, selectable = false, active = false, animation = {}, noTitle = false, ...rootDomAttributes }) => {
    // TODO
    let imageGroupClass = 'image-group'

    if (small) {
        imageGroupClass += ' image-group--sm'
    }

    if (selectable) {
        imageGroupClass += ' image-group--selectable'
    }

    if (active) {
        imageGroupClass += ' active'
    }

    return (
        <div className={imageGroupClass} {...animation} {...rootDomAttributes} >
            {/* Image */}
            <GatsbyImage alt={basicInfoWithImage.image.alternativeText} image={getImage(basicInfoWithImage.image.localFile)} />
            {/* Title */}
            <div className="image-group__text">
                {basicInfoWithImage.title && !noTitle && 
                    (selectable ? (
                        <h6>{basicInfoWithImage.title}</h6>
                    ) : (
                    <h5>{basicInfoWithImage.title}</h5>
                    ))
                }
               
                {/* Text */}
                {basicInfoWithImage.description && 
                    <ReactMarkdown
                        children={basicInfoWithImage.description}
                        rehypePlugins={[rehypeRaw]}
                    />
                }

                {selectable && 
                    <span>{basicInfoWithImage.minute}</span>
                }
            </div>
        </div>
    )
}

export default ImageGroup